var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column overflow-hidden h-100"},[_c('vue-custom-scrollbar',{staticClass:"app-content-padding flex-grow-1 overflow-hidden h-100"},[_c('div',{staticClass:"demo-spin-article"},[_c('ts-panel',[_c('form-wizard',{ref:"wizard",attrs:{"start-index":_vm.activeIndex,"color":"#00acac","title":'',"subtitle":'',"finishButtonText":"Finish","errorColor":"red"},on:{"update:startIndex":function($event){_vm.activeIndex=$event},"update:start-index":function($event){_vm.activeIndex=$event},"on-complete":_vm.onComplete},scopedSlots:_vm._u([{key:"step",fn:function(props){return _c('wizard-step',{attrs:{"tab":props.tab,"transition":props.transition,"index":props.index}},[_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center"},attrs:{"slot":"active-step"},slot:"active-step"},[(props.index == 2)?_c('lottie',{attrs:{"options":_vm.defaultOptions,"height":65,"width":65}}):_c('lottie',{attrs:{"options":_vm.defaultOptions2,"height":65,"width":65}})],1)])}},{key:"footer",fn:function(props){return [_c('div',{staticClass:"wizard-footer-left"},[(props.activeTabIndex == 0)?_c('wizard-button',{style:('background:#e2e2e2'),nativeOn:{"click":function($event){return _vm.$router.push({
                                        name: 'customer-loan-request'
                                    })}}},[_vm._v(_vm._s(_vm.$t("cancel")))]):_vm._e()],1),_c('div',{staticClass:"wizard-footer-left"},[(
                                    props.activeTabIndex > 0 &&
                                        !props.isLastStep
                                )?_c('wizard-button',{style:('background:#e2e2e2'),nativeOn:{"click":function($event){return props.prevTab()}}},[_vm._v(_vm._s(_vm.$t("customerLoanRequest.previous")))]):_vm._e()],1),_c('div',{staticClass:"wizard-footer-right"},[(!props.isLastStep)?_c('wizard-button',{staticClass:"wizard-footer-right",style:(props.fillButtonStyle),attrs:{"disabled":_vm.nextStep},nativeOn:{"click":function($event){return props.nextTab()}}},[(_vm.waiting)?_c('i',{staticClass:"fas fa-circle-notch fa-spin mr-2"}):_vm._e(),_vm._v(" "+_vm._s(props.activeTabIndex == 1 ? _vm.$t("customerLoanRequest.approve") : _vm.$t("customerLoanRequest.next"))+" ")]):_c('wizard-button',{staticClass:"wizard-footer-right finish-button",style:(props.fillButtonStyle),attrs:{"disabled":_vm.nextStep},nativeOn:{"click":function($event){return _vm.onComplete.apply(null, arguments)}}},[(_vm.waiting)?_c('i',{staticClass:"fa fa-spinner spin mr-2"}):_vm._e(),_vm._v(" "+_vm._s(props.isLastStep ? _vm.$t("customerLoanRequest.done") : _vm.$t("customerLoanRequest.next"))+" ")]),(
                                    props.activeTabIndex == 1 &&
                                        !props.isLastStep
                                )?_c('ts-button',{staticClass:"tw-mr-2",attrs:{"color":"danger","outline":"","waiting":_vm.rejecting,"disabled":_vm.rejecting},on:{"click":function($event){$event.preventDefault();return _vm.onReject.apply(null, arguments)}}},[(!_vm.rejecting)?_c('i',{staticClass:"far fa-times-circle"}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("customerLoanRequest.reject")))]):_vm._e(),(props.isLastStep)?_c('ts-button',{staticClass:"tw-mr-2",attrs:{"color":"warning","outline":""},on:{"click":function($event){return _vm.customerContract()}}},[_c('i',{staticClass:"far fa-file-pdf"}),_vm._v(" "+_vm._s(_vm.$t("customerLoanRequest.loanAgreement")))]):_vm._e()],1)]}}])},[_c('tab-content',{staticStyle:{"min-height":"460px !important"},attrs:{"title":_vm.$t('customerLoanRequest.loanRequestInformation'),"icon":"fas fa-file-invoice-dollar"}},[_c('show')],1),_c('tab-content',{staticStyle:{"min-height":"460px !important"},attrs:{"title":_vm.$t('customerLoanRequest.loanApproval'),"icon":"fas fa-hand-holding-usd","before-change":_vm.onApprove}},[_c('loan-approval',{ref:"loanApproval",attrs:{"validation":_vm.errors},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1),_c('tab-content',{staticStyle:{"min-height":"460px !important"},attrs:{"title":"Done","icon":" "}},[(_vm.activeIndex == 1)?_c('lottie',{attrs:{"options":_vm.defaultOptions,"height":200,"width":200}}):_vm._e(),_c('show')],1)],1)],1)],1),(_vm.loading)?_c('Spin',{attrs:{"size":"large","fix":""}}):_vm._e()],1),_c('contract-pdf-preview',{attrs:{"request-id":_vm.request_id},on:{"cancel":function (value) { return (_vm.contract_pdf_view = value); }},model:{value:(_vm.contract_pdf_view),callback:function ($$v) {_vm.contract_pdf_view=$$v},expression:"contract_pdf_view"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }