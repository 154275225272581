<template>
    <div>
        <form class="form-horizontal form-horizontal-text-right">
            <div class="row">
                <div class="col-md-8">
                    <div
                        class="row"
                        :class="{
                            'mb-3': !loanCurrency
                        }"
                    >
                        <label
                            class="form-label col-form-label col-md-4 required"
                            >{{
                                $t("customerLoanRequest.amountApprove")
                            }}</label
                        >
                        <div class="col-md-7">
                            <div
                                class="tw-flex tw-justify-between tw-space-x-1 tw-whitespace-nowrap"
                            >
                                <Poptip trigger="focus">
                                    <Input
                                        v-model.number="model.app_amount"
                                        :placeholder="
                                            $t(
                                                'customerLoanRequest.enterNumber'
                                            )
                                        "
                                        style="width: 103%"
                                        :class="{
                                            'ivu-form-item-error':
                                                errors.has('app_amount') ||
                                                $v.model.app_amount.$error
                                        }"
                                        ref="app_amount"
                                        @on-change="calculateAdminFee"
                                    >
                                        <i
                                            class="fas fa-dollar-sign"
                                            slot="prefix"
                                            v-if="model.currency_id == 1"
                                        ></i>
                                        <i
                                            class="far fa-registered"
                                            slot="prefix"
                                            v-if="model.currency_id == 2"
                                        ></i>
                                        <i
                                            class="fas fa-bold"
                                            slot="prefix"
                                            v-if="model.currency_id == 3"
                                        ></i>
                                    </Input>
                                    <div slot="content">
                                        {{
                                            model.app_amount
                                                ? formatNumber(
                                                      model.app_amount,
                                                      model.currency_id
                                                  )
                                                : $t(
                                                      "customerLoanRequest.enterNumber"
                                                  )
                                        }}
                                    </div>
                                </Poptip>
                                <label
                                    class="form-label col-form-label required"
                                    >{{
                                        $t("customerLoanRequest.interestRate")
                                    }}</label
                                >
                                <InputNumber
                                    :max="100"
                                    v-model="model.app_interest_rate"
                                    :formatter="value => `${value}%`"
                                    :parser="value => value.replace('%', '')"
                                    :placeholder="
                                        $t('customerLoanRequest.interestRate')
                                    "
                                    :class="{
                                        'ivu-form-item-error':
                                            errors.has('app_interest_rate') ||
                                            $v.model.app_interest_rate.$error
                                    }"
                                    style="width: 30%"
                                ></InputNumber>
                                <span
                                    class="validate text-danger"
                                    v-if="appInterestRateErrors.length > 0"
                                    >{{ appInterestRateErrors[0] }}</span
                                >
                            </div>
                            <span
                                class="validate text-danger"
                                v-if="appAmountErrors.length > 0"
                                >{{ appAmountErrors[0] }}</span
                            >
                        </div>
                    </div>
                    <div class="row mb-3" v-if="loanCurrency">
                        <label
                            class="form-label col-form-label col-md-4"
                        ></label>
                        <div class="col-md-7">
                            <div
                                class="tw-flex tw-justify-between tw-space-x-1 tw-whitespace-nowrap"
                            >
                                <span class="badge bg-success rounded-pill"
                                    >Min amount
                                    {{
                                        formatNumber(
                                            loanCurrency.min_size,
                                            model.currency_id
                                        )
                                    }}
                                    Max amount
                                    {{
                                        formatNumber(
                                            loanCurrency.max_size,
                                            model.currency_id
                                        )
                                    }}</span
                                >
                                <span class="badge bg-blue rounded-pill"
                                    >Min rate {{ loanCurrency.min_rate }} Max
                                    rate {{ loanCurrency.max_rate }}</span
                                >
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label
                            class="form-label col-form-label col-md-4 required"
                            >{{
                                $t("customerLoanRequest.installmentType")
                            }}</label
                        >
                        <div class="col-md-7">
                            <div
                                class="tw-flex tw-whitespace-nowrap tw-space-x-1 tw-justify-between tw-items-center"
                            >
                                <RadioGroup
                                    v-model="model.app_installment_type_id"
                                    size="small"
                                    type="button"
                                    :class="{
                                        'ivu-form-item-error':
                                            errors.has(
                                                'app_installment_type_id'
                                            ) ||
                                            $v.model.app_installment_type_id
                                                .$error
                                    }"
                                >
                                    <Radio
                                        class="mt-1"
                                        :label="c.id"
                                        v-for="(c, index) in installmentTypes"
                                        :key="index"
                                    >
                                        {{ c.label }}
                                    </Radio>
                                </RadioGroup>
                            </div>
                            <span
                                class="validate text-danger"
                                v-if="appInstallmentTypeIdErrors.length > 0"
                                >{{ appInstallmentTypeIdErrors[0] }}</span
                            >
                            <div
                                class="text-danger"
                                v-if="errors.has('app_installment_type_id')"
                            >
                                {{ errors.first("app_installment_type_id") }}
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label
                            class="form-label col-form-label col-md-4 required"
                            >{{
                                $t("customerLoanRequest.installmentType")
                            }}</label
                        >
                        <div class="col-md-7">
                            <div class="tw-flex tw-justify-between">
                                <div v-if="model.app_installment_type_id == 2">
                                    <ts-checkbox
                                        v-model="model.interval_1_week"
                                        >{{
                                            $t(
                                                "customerLoanRequest.interval1Week"
                                            )
                                        }}</ts-checkbox
                                    >
                                </div>
                                <div v-if="model.app_installment_type_id == 2">
                                    <ts-checkbox
                                        v-model="model.interval_2_week"
                                        >{{
                                            $t(
                                                "customerLoanRequest.interval2Week"
                                            )
                                        }}</ts-checkbox
                                    >
                                </div>
                                <div v-if="model.app_installment_type_id == 3">
                                    <ts-checkbox
                                        v-model="model.interval_1_month"
                                        >{{
                                            $t(
                                                "customerLoanRequest.interval1Month"
                                            )
                                        }}</ts-checkbox
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label
                            class="form-label col-form-label col-md-4 required"
                            >{{
                                $t(
                                    "customerLoanRequest.installmentTypeDuration"
                                )
                            }}</label
                        >
                        <div class="col-md-7">
                            <InputNumber
                                v-model.number="model.app_loan_duration"
                                :min="1"
                                :class="{
                                    'ivu-form-item-error':
                                        errors.has('app_loan_duration') ||
                                        $v.model.app_loan_duration.$error
                                }"
                            ></InputNumber>
                            <br />
                            <span
                                class="validate text-danger"
                                v-if="appLoanDurationErrors.length > 0"
                                >{{ appLoanDurationErrors[0] }}</span
                            >
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label
                            class="form-label col-form-label col-md-4 required"
                            >{{
                                $t("customerLoanRequest.adminFeeFirstOpening")
                            }}</label
                        >
                        <div class="col-md-7">
                            <div
                                class="tw-flex tw-justify-between tw-space-x-1 tw-whitespace-nowrap"
                            >
                                <label
                                    class="form-label col-form-label tw-ml-16"
                                >
                                    {{ adminFeeRate ? adminFeeRate + "%" : "" }}
                                    {{
                                        $t("customerLoanRequest.ofApprovedLoan")
                                    }}</label
                                >

                                <Poptip trigger="focus">
                                    <Input
                                        v-model.number="model.admin_fee"
                                        :placeholder="
                                            $t(
                                                'customerLoanRequest.enterNumber'
                                            )
                                        "
                                        style="width: 103%"
                                        :class="{
                                            'ivu-form-item-error':
                                                errors.has('admin_fee') ||
                                                $v.model.admin_fee.$error
                                        }"
                                        ref="admin_fee"
                                    >
                                        <i
                                            class="fas fa-dollar-sign"
                                            slot="prefix"
                                            v-if="model.currency_id == 1"
                                        ></i>
                                        <i
                                            class="far fa-registered"
                                            slot="prefix"
                                            v-if="model.currency_id == 2"
                                        ></i>
                                        <i
                                            class="fas fa-bold"
                                            slot="prefix"
                                            v-if="model.currency_id == 3"
                                        ></i>
                                    </Input>
                                    <div slot="content">
                                        {{
                                            model.admin_fee
                                                ? formatNumber(
                                                      model.admin_fee,
                                                      model.currency_id
                                                  )
                                                : $t(
                                                      "customerLoanRequest.enterNumber"
                                                  )
                                        }}
                                    </div>
                                </Poptip>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label class="form-label col-form-label col-md-4"
                            >{{
                                $t("customerLoanRequest.creditCheckFee")
                            }}
                            (CBC)</label
                        >
                        <div class="col-md-7">
                            <Poptip trigger="focus">
                                <Input
                                    v-model.number="model.cbc_fee"
                                    :placeholder="
                                        $t('customerLoanRequest.enterNumber')
                                    "
                                    style="width: 103%"
                                    :class="{
                                        'ivu-form-item-error': errors.has(
                                            'cbc_fee'
                                        )
                                    }"
                                    ref="cbc_fee"
                                >
                                    <i
                                        class="fas fa-dollar-sign"
                                        slot="prefix"
                                        v-if="model.currency_id == 1"
                                    ></i>
                                    <i
                                        class="far fa-registered"
                                        slot="prefix"
                                        v-if="model.currency_id == 2"
                                    ></i>
                                    <i
                                        class="fas fa-bold"
                                        slot="prefix"
                                        v-if="model.currency_id == 3"
                                    ></i>
                                </Input>
                                <div slot="content">
                                    {{
                                        model.cbc_fee
                                            ? formatNumber(
                                                  model.cbc_fee,
                                                  model.currency_id
                                              )
                                            : $t(
                                                  "customerLoanRequest.enterNumber"
                                              )
                                    }}
                                </div>
                            </Poptip>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label class="form-label col-form-label col-md-4"
                            >{{ $t("customerLoanRequest.monthlyFee") }}
                            {{
                                monthlyFeeRate
                                    ? monthlyFeeRate +
                                      "% " +
                                      $t("customerLoanRequest.ofPrincipal")
                                    : ""
                            }}</label
                        >
                        <div class="col-md-7">
                            <Poptip trigger="focus">
                                <Input
                                    v-model.number="
                                        model.monthly_collection_fee
                                    "
                                    :placeholder="
                                        $t('customerLoanRequest.enterNumber')
                                    "
                                    style="width: 103%"
                                    :class="{
                                        'ivu-form-item-error': errors.has(
                                            'monthly_collection_fee'
                                        )
                                    }"
                                    ref="monthly_collection_fee"
                                >
                                    <i
                                        class="fas fa-dollar-sign"
                                        slot="prefix"
                                        v-if="model.currency_id == 1"
                                    ></i>
                                    <i
                                        class="far fa-registered"
                                        slot="prefix"
                                        v-if="model.currency_id == 2"
                                    ></i>
                                    <i
                                        class="fas fa-bold"
                                        slot="prefix"
                                        v-if="model.currency_id == 3"
                                    ></i>
                                </Input>
                                <div slot="content">
                                    {{
                                        model.monthly_collection_fee
                                            ? formatNumber(
                                                  model.monthly_collection_fee,
                                                  model.currency_id
                                              )
                                            : $t(
                                                  "customerLoanRequest.enterNumber"
                                              )
                                    }}
                                </div>
                            </Poptip>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label
                            class="form-label col-form-label col-md-4 required"
                            >{{
                                $t("customerLoanRequest.disbursementDate")
                            }}</label
                        >
                        <div class="col-md-7">
                            <div
                                class="tw-flex tw-justify-between tw-space-x-1 tw-whitespace-nowrap"
                            >
                                <DatePicker
                                    :value="model.app_disbursement_date"
                                    placeholder="DD-MM-YYYY"
                                    format="dd-MM-yyyy"
                                    @on-change="onChangeDisbursementDate"
                                ></DatePicker>
                                <label
                                    class="form-label col-form-label required"
                                    >{{
                                        $t(
                                            "customerLoanRequest.firstInstallmentDate"
                                        )
                                    }}</label
                                >
                                <DatePicker
                                    :value="model.app_first_installment_date"
                                    placeholder="DD-MM-YYYY"
                                    format="dd-MM-yyyy"
                                    @on-change="onChangeFirstInstallmentDate"
                                ></DatePicker>
                                <span
                                    class="validate text-danger"
                                    v-if="appInterestRateErrors.length > 0"
                                    >{{ appInterestRateErrors[0] }}</span
                                >
                            </div>
                            <span
                                class="validate text-danger"
                                v-if="appAmountErrors.length > 0"
                                >{{ appAmountErrors[0] }}</span
                            >
                        </div>
                    </div>
                    <div class="row mb-3">
                        <label
                            class="form-label col-form-label col-md-4"
                            :class="{
                                required: errors.has('approval_comment')
                            }"
                            >{{ $t("customerLoanRequest.comments") }}</label
                        >
                        <div class="col-md-7">
                            <textarea
                                class="form-control"
                                v-model="model.approval_comment"
                                rows="3"
                                :class="{
                                    'is-invalid': errors.has('approval_comment')
                                }"
                            ></textarea>
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('approval_comment')"
                            >
                                {{ errors.first("approval_comment") }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4"></div>
            </div>
        </form>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapGetters, mapState } from "vuex";
import { sortBy } from "lodash";
import { required } from "vuelidate/lib/validators";
import moment from "moment";

export default {
    name: "loanApproval",
    props: ["value", "validation"],
    data() {
        return {
            model: {},
            employeeLoading: false,
            bmEmployeeLoading: false,
            loanCurrency: null,
            adminFeeRate: null,
            monthlyFeeRate: null,
            errors: new Errors(),
            installment_types: {
                daily: {
                    id: 1,
                    label: "Daily"
                },
                weekly: {
                    id: 2,
                    label: "Weekly"
                },
                monthly: {
                    id: 3,
                    label: "Monthly"
                }
            }
        };
    },
    created() {
        this.model = this.value;
    },
    validations: {
        model: {
            app_amount: { required },
            app_installment_type_id: { required },
            app_loan_duration: { required },
            app_interest_rate: { required },
            admin_fee: { required }
        },
        form: [
            "model.app_amount",
            "model.app_installment_type_id",
            "model.app_loan_duration",
            "model.app_interest_rate",
            "model.admin_fee"
        ]
    },
    computed: {
        ...mapState("creditOperation/customerLoanRequest", [
            "edit_data",
            "loanTypes"
        ]),
        ...mapGetters(["formatNumber"]),
        installmentTypes() {
            let newObj = [];

            let loanType = this.loanTypes.find(
                l => l.loan_type_id == this.model.loan_type_id
            );

            if (loanType) {
                let { daily, monthly, weekly } = loanType;

                if (daily) {
                    newObj.push(this.installment_types["daily"]);
                }
                if (monthly) {
                    newObj.push(this.installment_types["monthly"]);
                }
                if (weekly) {
                    newObj.push(this.installment_types["weekly"]);
                }

                return sortBy(newObj, ["id"]);
            }

            return Object.values(this.installment_types);
        },
        locale() {
            return this.$root.$i18n.locale;
        },
        appAmountErrors() {
            const errors = [];
            if (!this.$v.model.app_amount.$error) return errors;
            !this.$v.model.app_amount.required && errors.push("Required");
            return errors;
        },
        appInterestRateErrors() {
            const errors = [];
            if (!this.$v.model.app_interest_rate.$error) return errors;
            !this.$v.model.app_interest_rate.required &&
                errors.push("Required");
            return errors;
        },
        appInstallmentTypeIdErrors() {
            const errors = [];
            if (!this.$v.model.app_installment_type_id.$error) return errors;
            !this.$v.model.app_installment_type_id.required &&
                errors.push("Required");
            return errors;
        },
        appLoanDurationErrors() {
            const errors = [];
            if (!this.$v.model.app_loan_duration.$error) return errors;
            !this.$v.model.app_loan_duration.required &&
                errors.push("Required");
            return errors;
        },
        adminFeeErrors() {
            const errors = [];
            if (!this.$v.model.admin_fee.$error) return errors;
            !this.$v.model.admin_fee.required && errors.push("Required");
            return errors;
        }
    },
    methods: {
        validateForm() {
            this.$v.form.$touch();
            var isValid = !this.$v.form.$invalid;
            return isValid;
        },
        calculateAdminFee() {
            this.model.admin_fee =
                (this.model.app_amount * this.adminFeeRate) / 100;
        },
        onChangeDisbursementDate(date) {
            this.model.app_disbursement_date = date;
            this.model.app_first_installment_date = moment(date, "DD-MM-YYYY")
                .add(1, "months")
                .format("DD-MM-YYYY");
        },
        onChangeFirstInstallmentDate(date) {
            this.model.app_first_installment_date = date;
        }
    },
    watch: {
        validation: {
            handler() {
                this.errors = new Errors(this.validation.errors);
            },
            deep: true
        },
        "model.loan_type_id": function(val) {
            if (val) {
                let loan_type = this.loanTypes.find(l => l.loan_type_id == val);
                if (loan_type) {
                    this.loanCurrency = loan_type.loan_currency;
                    this.adminFeeRate = loan_type.admin_fee_rate;
                    this.monthlyFeeRate = loan_type.monthly_fee_rate;
                    this.model.admin_fee =
                        (this.model.app_amount * loan_type.admin_fee_rate) /
                        100;
                    this.model.monthly_collection_fee =
                        (this.model.app_amount * loan_type.monthly_fee_rate) /
                        100;
                }
            }
        },
        "model.app_installment_type_id": function(val) {
            if (val) {
                this.model.interval_1_month = false;
                this.model.interval_1_week = false;
                this.model.interval_2_week = false;
                this.model.payment_interval = 1;
            }
        },
        "model.interval_2_week": function(val) {
            if (val) {
                this.model.payment_interval = 2;
                this.model.interval_1_week = false;
            } else {
                this.model.payment_interval = 1;
            }
        },
        "model.interval_1_week": function(val) {
            if (val) {
                this.model.payment_interval = 1;
                this.model.interval_2_week = false;
            }
        },
        installmentTypes: function(val) {
            if (val.length === 1) {
                this.model.installment_type_id = val[0].id;
            }
        }
    }
};
</script>
